.Pallete{
    min-height: 150px;
    display: flex;
    flex-wrap: wrap;
}

.ColorCircle{
    position: relative;
    color: white;
    text-align: center;
    width: 100%;
    height: 50px;
    margin: 3px 0px;
    background-color: black;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.ColorCircle > p{
    position: absolute;
    width: fit-content;
    margin: 0px;
    top: 50%;
    left: 50%;
    font-size: 12px;
    transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);
    -o-transform:translate(-50%,-50%);

    font-weight: 300;
}