.TermofUse{
    color: #aaaaaa;
    max-width: 600px;
}
.TermofUse:hover{
    transform: scale(1)!important;
    -webkit-transform: scale(1)!important;
    -moz-transform: scale(1)!important;
    -ms-transform: scale(1)!important;
    -o-transform: scale(1)!important;
}

.TermofUse strong{
    font-size: 20px;
}