.PromotionInstructions{
    color: #aaaaaa;
    max-width: 600px;
}
.PromotionInstructions:hover{
    transform: scale(1)!important;
    -webkit-transform: scale(1)!important;
    -moz-transform: scale(1)!important;
    -ms-transform: scale(1)!important;
    -o-transform: scale(1)!important;
}

.PromotionInstructions .SubTitle{
    /* float: right; */
    margin: 0;
    transform: translateY(-10px);
}

.PromotionInstructions h3{
    color: white;
    font-size: 1.5em;
}

.PromotionInstructions strong{
    color: white;
    font-size: 20px;
}

.PromotionInstructions a{
    color: rgb(224, 158, 35);
    text-decoration: underline;
}

.PromotionInstructions hr{
    border-color: rgba(150,150,150,0.5);
}

.PromotionInstructions .svg{
    filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.PlatformLinks{
    display: flex;
    height: 100px;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 50px;
    color: rgba(0,0,0,0.7);
    font-weight: 500;
    overflow: hidden;
}
.PlatformLinks > .PlatformCard{
    width: 33.33%;
    border-right: 1px solid rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.PlatformLinks > .PlatformCard.active{
    background-color: white;
}
.PlatformLinks > .PlatformCard *{
    margin: 10px;
}
.PlatformLinks > .PlatformCard img{
    height: 50px;
}

.InstructionTitle{
    text-align: center;
    white-space: pre-line;
}

@media only screen and (max-width: 600px) {
    .PlatformLinks{
        height: 130px;
    }
    .PlatformLinks > .PlatformCard p{
        margin-top: 0px;
    }
    .PlatformLinks > .PlatformCard{
        width: 33.33%;
        border-right: 1px solid rgba(0,0,0,0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .InstructionTitle h1{
        font-size: 24px;
    }
  }
