.ContentBox{
    margin-bottom: 50px;
    width: 100%;
    transition: height .6s ease-out;
    animation: fadein 1s, bottomin 1s ease-out;
    -webkit-animation: fadein 1s, bottomin 1s ease-out;
    -webkit-transition: height .6s ease-out;
    -moz-transition: height .6s ease-out;
    -ms-transition: height .6s ease-out;
    -o-transition: height .6s ease-out;

    white-space: pre-line;
}
.ContentBox h1{
    font-size: 34px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.Content{
    width: 100%;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: flex-start; */

    /* transition: all ease-out .4s;
    -webkit-transition: all ease-out .4s;
    -moz-transition: all ease-out .4s;
    -ms-transition: all ease-out .4s;
    -o-transition: all ease-out .4s; */
}
/* .Content:hover{
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
} */
.ContentBox{
    position: relative;
}
.ContentBox .Title{
    font-weight: 700;
}

.Content > div,
.Content > a{
    transition: all ease-out .4s;
    -webkit-transition: all ease-out .4s;
    -moz-transition: all ease-out .4s;
    -ms-transition: all ease-out .4s;
    -o-transition: all ease-out .4s;
}
.Content > div:hover,
.Content > a:hover{
    transform: scale(1.025);
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    -o-transform: scale(1.025);
}
.ContentBox .More{
    position: absolute;
    right: 5px;
    top: 15px;
}
.ContentBox .More:hover{
    color: var(--yellow);
}