.FactsheetWrapper{
    width: 350px;
    max-width: 100vw;
    position: relative;
    padding: 20px 30px;
    box-sizing: border-box;
    border-left: 1px solid rgba(255,255,255,0.1);
    background-color: var(--main-bg-1);
}
.Factsheet{
    width: 290px;
    max-width: calc(100vw - 60px);
    box-sizing: border-box;
    position: fixed;
}
.Factsheet a{
    color: rgb(185, 185, 185);
}
.FactsheetWrapper .ToggleButton{
    display: none;
}

@media screen and (max-width: 1440px) {
    .FactsheetWrapper{
        height: 100vh;
        right: -350px;
        position: fixed;
        transition: right .4s ease-out;
        -webkit-transition: right .4s ease-out;
        -moz-transition: right .4s ease-out;
        -ms-transition: right .4s ease-out;
        -o-transition: right .4s ease-out;
        z-index: 51;
}
    .FactsheetWrapper.open{
        right: 0px;
    }
    .FactsheetWrapper .ToggleButton{
        display: block;
        padding: 10px 20px;
        height: 50px;
        width: 120px;
        text-align: center;
        background-color: var(--main-bg-1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: none;
        border-radius: 10px 10px 0px 0px;
        box-sizing: border-box;
        position: fixed;
        top: 150px;
        right: 0px;
        transform: rotate(-90deg) translateY(40px);
        -webkit-transform: rotate(-90deg) translateY(40px);
        -moz-transform: rotate(-90deg) translateY(40px);
        -ms-transform: rotate(-90deg) translateY(40px);
        -o-transform: rotate(-90deg) translateY(40px);
        -webkit-border-radius: 10px 10px 0px 0px;
        -moz-border-radius: 10px 10px 0px 0px;
        -ms-border-radius: 10px 10px 0px 0px;
        -o-border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        z-index: 50;
        transition: color .4s ease-out;
        -webkit-transition: color .4s ease-out;
        -moz-transition: color .4s ease-out;
        -ms-transition: color .4s ease-out;
        -o-transition: color .4s ease-out;
}
    .FactsheetWrapper .ToggleButton.open{
        color: var(--highlight-color)
    }
}

@media screen and (max-width: 896px) {

}

@media screen and (max-width: 480px) {

}