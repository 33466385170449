@keyframes fadein{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes fadeout{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes scalein{
    0%{
        transform: scale(0.8);
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
        -o-transform: scale(0.8);
    }
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}
@keyframes leftin{
    0%{
        transform: translateX(-250px);
        -webkit-transform: translateX(-250px);
        -moz-transform: translateX(-250px);
        -ms-transform: translateX(-250px);
        -o-transform: translateX(-250px);
}
    100%{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
}
}
@keyframes bottomin{
    0%{
        transform: translateY(100px);
        -webkit-transform: translateY(100px);
        -moz-transform: translateY(100px);
        -ms-transform: translateY(100px);
        -o-transform: translateY(100px);
}
    100%{
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
    }
}

.SocialIcons svg{
    fill: var(--main-text);
    width: 40px;
}

.Navigation{
    position: fixed;
    z-index: 100;

    width: 250px;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    background-color: var(--main-bg);
    overflow: hidden;
}
.Navigation .MainLogo{
    width: 80px;
}
.Navigation .MainLogo .cls-1{fill:#d23c19;}
.Navigation .MainLogo .cls-2{fill:#3278f0;}
.Navigation .MainLogo .cls-3{fill:#ddd;}
.Navigation .MainLogo .cls-4{fill:#f0c846;}

.Navigation > div{
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    transition: all .5s ease-out;
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
}
.Navigation .HomeLink{
    min-height: 150px;
    height: 25%;
    font-size: 10px;
    /* animation: fadein 2s;
    -webkit-animation: fadein 2s; */
}
.Navigation .NavList{
    min-height: 300px;
    height: 50%;
    /* opacity: 0;
    animation: fadein 2s;
    -webkit-animation: fadein 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards; */
}
.Navigation .SubscribeModule{
    min-height: 100px;
    height: 17%;
    /* opacity: 0;
    animation: fadein 1s, leftin 1s;
    -webkit-animation: fadein 1s, leftin 1s;
    animation-delay: fadein 2s, leftin 0s;
    animation-fill-mode: forwards; */
}
.Navigation .SocialNetwork{
    min-height: 50px;
    height: 8%;
    /* animation: fadein 2s;
    -webkit-animation: fadein 2s; */
}
.Navigation .ReponsiveTitle{
    display: none;
}


.NavList nav{

}
.NavList nav > ul{
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.NavList nav > ul > li{
    position: relative;

    margin: 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.NavList nav > ul > li > a{
    font-size: 28px;
    font-weight: 500;
}
.NavList nav > ul > li > a.active{

}
.NavList nav > ul > li .NavUnderline{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 150px;
}
.NavList nav > ul > li:hover .NavUnderline{

}
.NavList nav > ul > li .NavUnderline > div{
    height: 1px;
    width: 0%;
    margin-bottom: 3px;
    opacity: 0;
    background-color: var(--main-text);
}
.NavList nav > ul > li:hover .NavUnderline > div{
    width: 100%;
    opacity: 0.8;
}
.NavList nav > ul > li .NavUnderline > div{
    transition: all .4s ease-out;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
}
.SubscribeModule{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.SubscribeModule h3{
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 24px;
}
.SubscribeModule form{
    display: flex;
    border-bottom: 2px solid rgba(200, 152, 50, 0.2);
    transition: all ease-out 1s;
    -webkit-transition: all ease-out 1s;
    -moz-transition: all ease-out 1s;
    -ms-transition: all ease-out 1s;
    -o-transition: all ease-out 1s;
    padding: 0px 10px;
    width: 150px;
}
.SubscribeModule:focus-within form{
    display: flex;
    border-bottom: 2px solid var(--yellow);
}
.SubscribeModule input{
    width: 70%;
    height: 30px;
    background-color: black;
    box-sizing: content-box;
    background: none;
    border: none;
    color: var(--main-text);
    font-family: inherit;
}

.SubscribeModule input::placeholder {
    font-size: 9px;
}
.SubscribeModule input:focus,
.SubscribeModule input:active{
    outline: none;
}
.SubscribeModule button{
    width: 30%;
    height: 30px;
    box-sizing: content-box;
    background: none;
    border: none;
    color: var(--main-text);
    font-family: inherit;
    font-size: 12px;
}
.SubscribeModule button:hover{
    color: var(--yellow);
}
.SubscribeModule p{
    font-size: 10px;
    margin-top: 8px;
    opacity: 0.5;
    transition: all ease-out 1s;
    -webkit-transition: all ease-out 1s;
    -moz-transition: all ease-out 1s;
    -ms-transition: all ease-out 1s;
    -o-transition: all ease-out 1s;
}
.SubscribeModule:focus-within p{
    opacity: 1;
}


@media screen and (max-width: 1440px) {
    .Navigation{
        position: fixed;
        top: 0px;
        width: 100vw;
        height: 80px;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: var(--main-bg);
        overflow: hidden;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        transition: all .4s ease-out;
        -webkit-transition: all .4s ease-out;
        -moz-transition: all .4s ease-out;
        -ms-transition: all .4s ease-out;
        -o-transition: all .4s ease-out;
}
    .Navigation .MainLogo{
        width: 40px;
        height: 40px;
    }
    .Navigation .NavList,
    .Navigation .SubscribeModule,
    .Navigation .SocialNetwork{
        display: none;
        animation: fadeout .4s ease-out;
        -webkit-animation: fadeout .4s ease-out;
        animation-fill-mode: forwards;
}
    .Navigation.open .NavList,
    .Navigation.open .SubscribeModule,
    .Navigation.open .SocialNetwork{
        display: flex;
        animation: fadein .4s ease-out;
        -webkit-animation: fadein .4s ease-out;
        animation-fill-mode: forwards;
}
    .Navigation > div{
        width: auto;
        display: block;
    }
    .Navigation > .HomeLink{
        position: absolute;
        top: 10px;
        left: 10px;

        min-height: inherit;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Navigation > .HomeLink .LogoTitle{
        display: none;
    }
    .Navigation .ReponsiveTitle{
        position: absolute;
        top: 27px;
        left: 50%;
        transform: translateX(-50%);

        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre-line;
        font-size: 14px;
        font-weight: 500;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
}
    .Navigation .ResponsiveMenu{
        position: absolute;
        top: 10px;
        right: 20px;

        height: 60px;
        width: 60px;
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }
    .Navigation .ResponsiveMenu .MenuStick{
        width: 100%;
        height: 2px;
        background-color: var(--main-text-80);
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
        transform: rotate(180deg) translate(0px, 0px);
        transition: transform .4s ease-out opacity .4s ease-out;
        -webkit-transition: transform .4s ease-out opacity .4s ease-out;
        -moz-transition: transform .4s ease-out opacity .4s ease-out;
        -ms-transition: transform .4s ease-out opacity .4s ease-out;
        -o-transition: transform .4s ease-out opacity .4s ease-out;
        opacity: 1;
        -webkit-transform: rotate(180deg) translate(0px, 0px);
        -moz-transform: rotate(180deg) translate(0px, 0px);
        -ms-transform: rotate(180deg) translate(0px, 0px);
        -o-transform: rotate(180deg) translate(0px, 0px);
}
    .Navigation.open .ResponsiveMenu .MenuStick:first-child{
        transform: rotate(-45deg) translate(-9.5px, 11px);
        -webkit-transform: rotate(-45deg) translate(-9.5px, 11px);
        -moz-transform: rotate(-45deg) translate(-9.5px, 11px);
        -ms-transform: rotate(-45deg) translate(-9.5px, 11px);
        -o-transform: rotate(-45deg) translate(-9.5px, 11px);
    }
    .Navigation.open .ResponsiveMenu .MenuStick:nth-child(2){
        opacity: 0;
    }
    .Navigation.open .ResponsiveMenu .MenuStick:last-child{
        transform: rotate(45deg) translate(-9px, -10px);
        -webkit-transform: rotate(45deg) translate(-9px, -10px);
        -moz-transform: rotate(45deg) translate(-9px, -10px);
        -ms-transform: rotate(45deg) translate(-9px, -10px);
        -o-transform: rotate(45deg) translate(-9px, -10px);
    }


    .Navigation.open{
        position: fixed;
    
        width: 100vw;
        height: 100vh;
        padding: 20px;
        box-sizing: border-box;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    
        background-color: var(--main-bg);
        overflow: hidden;
    }
    .Navigation.open .MainLogo{
        width: 80px;
    }
    .Navigation.open .ReponsiveTitle{
        /* display: none; */
    }
}

@media screen and (max-width: 896px) {
    
}

@media screen and (max-width: 480px) {
    
}