.ColorGen {
    text-align: center;
    min-height: 100vh;
    position: relative;
    width: 100%;
  }
  
  .ToolWrapper{
    width: 100%;
    margin: auto;
    min-height: 400px;
    text-align: left;
  }
  .ToolWrapper h1{
    font-size: 40px;
  }
  .ToolWrapper h1 span{
    font-size: 20px;
    font-weight: 300;
  }
  .CustomRanges{
    margin-bottom: 40px;
    width: 100%;
  }
  .CustomRanges > p{
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .CustomRanges .rc-slider-track{
    background-color: var(--yellow);
  }
  .CustomRanges .rc-slider-handle,
  .CustomRanges .rc-slider-dot-active{
    border: solid 2px #777777;
  }
  input[type="checkbox"]{
    margin: 3px 10px;
  }
  .OptionWrapper{
    margin-top: 40px;
    display: flex;
  }
  .OptionWrapper button{
    width: 150px;
    height: 25px;
    background-color: #777777;
    color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-color: #777777;
    border-style: solid;
  }

  .rc-slider-step {
    /* background: transparent; */
    background-color: rgba(0,0,0,0.3);
}

.RangeWrapper{
    margin-bottom: 100px;
}