.MoreButton{
    padding: 7px 12px;
    margin-bottom: 31px;
    background-color: var(--main-bg-1);
    border-radius: 8px;
    transform: translateY(-30px);
    cursor: pointer;
    transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
}
.MoreButton:hover{
    background-color: var(--main-text);
    color: var(--main-bg);
    transform: scale(1.1) translateY(-26px);
    -webkit-transform: scale(1.1) translateY(-26px);
    -moz-transform: scale(1.1) translateY(-26px);
    -ms-transform: scale(1.1) translateY(-26px);
    -o-transform: scale(1.1) translateY(-26px);
}

.TwitterWidget{
    background-color: var(--main-bg-3) !important;
}
.Content.SocialMedia > div:first-of-type{
    width: 680px;
}
.Content.SocialMedia > div:hover,
.Content.SocialMedia > a:hover{
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

.InstagramPost iframe{
    width: 100%!important;
    min-width: auto!important;
}

@media screen and (max-width: 1440px) {
    .Content.SocialMedia > div:first-of-type{
        width: 500px;
    }

}
@media screen and (max-width: 896px) {
    .Content.SocialMedia > div:first-of-type{
        width: calc(100% - 350px);
    }
    .Dummy{
        width: 20px!important;
    }
}
@media screen and (max-width: 700px) {
    .Content.SocialMedia > div:first-of-type{
        width: calc(100% - 20px);
    }
    .InstagramPostWrapper::before{
        content: "Instagram Posts";
        position: absolute;
        font-weight: 700;
        font-size: calc(1vw + 15px);
        transform: translateY(-35px)    ;
        -webkit-transform: translateY(-35px)    ;
        -moz-transform: translateY(-35px)    ;
        -ms-transform: translateY(-35px)    ;
        -o-transform: translateY(-35px)    ;
}
    .InstagramPostWrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-top: 100px;
    }
    .InstagramPost{
        margin: 10px;
    }
    .Dummy{
        display: none;
    }
}
@media screen and (max-width: 480px) {
    .InstagramPostWrapper{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .Dummy{
        display: none;
    }
}