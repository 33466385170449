.TextContent{
    padding: 50px;
    box-sizing: border-box;
}
.TextContent hr{
    margin: 0px;
}

@media only screen and (max-width: 1440px){
    /* .TextContent{
        padding: 50px;
        box-sizing: border-box;
    } */
}

@media screen and (max-width: 896px){
    .TextContent{
        padding: 20px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 480px){
    .TextContent{
        padding: 20px;
        box-sizing: border-box;
    }
}
