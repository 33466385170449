.ImageCard{
    width: 100%;
    min-height: 300px;

    display: flex;
    flex-direction: row;
}
.ImageCard > div{
    height: 100%;
}
.ImageCard .Image{
    width: 75%;
}
.ImageCard .Image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6) contrast(0.8);
    -webkit-filter: brightness(0.6) contrast(0.8);
    transition: all .4s ease-out;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
}
.ImageCard:hover .Image img{
    filter: brightness(1);
    -webkit-filter: brightness(1);
}
.ImageCard .TextBox{
    width: 25%;
    min-width: 200px;
    background-color: var(--main-bg-3);
    padding: 20px 30px;
    box-sizing: border-box;
    word-break: break-word;
}

.ImageCard .TextBox h3{
    font-size: 24px;
    font-weight: 700;
    margin: 0px;
}
.ImageCard .TextBox p{
    font-size: 10px;
    font-weight: 400;
}
.ImageCard .TextBox .Links{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.ImageCard .TextBox .Links a{
    font-weight: 500;
    font-size: 14px;
    margin-right: 10px;
    text-decoration: underline;
}



@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 896px) {
    
}

@media screen and (max-width: 480px) {
    .ImageCard{
        width: 100%;
        min-height: 300px;

        display: flex;
        flex-direction: column;
    }
    .ImageCard .Image{
        width: 100% !important;
    }
    .ImageCard .TextBox{
        width: 100% !important;
    }
}