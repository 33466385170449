.WorkPage{
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: pre-line;
}
.WorkPageContent{
    width: calc(100% - 350px);
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    padding-bottom: 100px;
}
.WorkContent{
    border-top: 1px solid rgba(255,255,255,0.1);
}
.WorkPage h1{
    font-size: 40px;
    margin: 0px;
}
.WorkPage h3{
    font-size: calc(.5vw + 18px);
    margin-bottom: 0px;
    margin-top: 0px;
}
.WorkPage p{
    font-size: 14px;
}
.WorkPage ul{
    list-style-type: none;
    padding-left: 3px;
}
.WorkPage li{
    font-size: 18px;
    margin-bottom: 5px;
}

.WorkPage .OtherWorks{
    width: 100%;
}
.WorkPage .WorkCard h1{
    font-size: 5vw;
}

.WorkContent.full{
    width: 100%;
}
.WorkContent.half{
    width: 50%;
}
.WorkContent.third{
    width: 33.3%;
}

.WorkContent.full.hd{
    height: calc(56.25vw - 337.5px);
}
.WorkContent.half.hd{
    /* height: calc(28.125vw - 168.75px); */
}
.WorkContent.third.hd{
    /* height: calc(28.125vw - 337.5px); */
}

.WorkContent.third.vertical{
    height: calc(59.26vw - 355px);
}
.WorkContent.half.vertical{
    height: calc(59.26vw - 355px);
}

@media only screen and (max-width: 1440px){
    .WorkPageContent{
        width: 100%;
    }
    .WorkContent.third.vertical{
        width: 33%;
        height: 59.26vw;
    }
    .WorkContent.half.vertical{
        width: 50%;
        height: 88.8vw;
    }
    .WorkContent.full.hd{
        height: 59.26vw;
    }
    /* .WorkContent.half.hd{
        height: 28.125vw;
    } */
    /* .WorkContent.third.hd{
        height: 18.74vw;
    } */
}

@media screen and (max-width: 896px){
    .WorkPage{

    }
    .WorkContent.full{
        width: 100%;
    }
    .WorkContent.half{
        width: 100%;
    }
    .WorkContent.third{
        width: 100%;
    }
    .WorkContent.hd{
        height: 59.26vw;
    }
    .WorkContent.third.vertical{
        width: 33%;
        height: 59.26vw;
    }
    .WorkContent.half.vertical{
        width: 50%;
        height: 59.26vw;
    }
    .WorkContent.third.vertical .ImageSlider > .SlideButton svg,
    .WorkContent.half.vertical .ImageSlider > .SlideButton svg {
        height: 25px;
        width: 25px;
    }
}

@media screen and (max-width: 480px){

    .WorkPage{

    }
    .WorkPageContent{
        width: 100%;

    }
    .WorkContent.full{
        width: 100%;
    }
    .WorkContent.half{
        width: 100%;
    }
    .WorkContent.third{
        width: 100%;
    }

    .WorkContent.third.vertical{
        width: 100%;
        height: 178vw;
    }
    .WorkContent.half.vertical{
        width: 100%;
        height: 178vw;
    }
    .WorkContent.third.vertical .ImageSlider > .SlideButton svg,
    .WorkContent.half.vertical .ImageSlider > .SlideButton svg {
        height: 40px;
        width: 40px;
    }
}
