.LinkContent{
    padding: 50px;
    box-sizing: border-box;
    position: relative;
}
.LinkContent:hover{
    color: var(--main-bg)
}
.LinkContent .BackgroundImage{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(.2) saturate(0.3);
    -webkit-filter: brightness(.2) saturate(0.3);
}
.LinkContent:hover .BackgroundImage{
    filter: brightness(5) saturate(.2) contrast(0.2);
    -webkit-filter: brightness(5) saturate(.2) contrast(0.2);
}
.LinkContent #link{
    position: absolute;
    margin-left: 50px;
    top: 50%;
    left: 0px;
    transform: translate(0%, -50%);
    width: 80px;
    fill: var(--main-text);
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
}
.LinkContent:hover #link{
    fill: var(--main-bg);
    /* stroke: black; */
}
.LinkContent h1,
.LinkContent h3,
.LinkContent p{
    margin-left: 100px;
}

@media screen and (max-width: 896px){
    .LinkContent{
        padding: 20px;
        box-sizing: border-box;
    }
    .LinkContent #link{
        margin-left: 20px;
        width: 60px;
    }
    .LinkContent h1,
    .LinkContent h3,
    .LinkContent p{
        margin-left: 80px;
    }
}

@media screen and (max-width: 480px){
    .LinkContent{
        padding: 20px;
        box-sizing: border-box;
    }
}
