@keyframes growimg{
    0%{
        height: 0px;
    }
    100%{
        height: 140px;
    }
}
@keyframes grow{
    0%{
        height: 0px;
    }
    100%{
        height: auto;
    }
}
.Newscard{
    white-space: pre-line;
    width: 340px;
    background-color: var(--main-bg-3);

    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;

    /* cursor: pointer; */
}

.NewsText{
    padding: 10px 30px;
    width: 100%;
    box-sizing: border-box;
    min-height: 100px;
    animation: grow .6s ease-out;
    -webkit-animation: grow .6s ease-out;
}

.NewsText > *{
    width: 100%;
}
.NewsText h3{
    margin-bottom: 0px;
    font-weight: 700;
    margin-top: 5px;
}
.NewsText p{
    margin-bottom: 0px;
    font-size: 12px;
}
.NewsText div:last-of-type{
    margin-top: 10px;
    margin-bottom: 10px;

}
.NewsText p:first-of-type{
    text-align: right;
    font-size: 10px;
    margin-top: 0px;
}
.NewsText h6{
    font-size: 16px;
    font-weight: 400;
}
.NewsText .Links{
    font-size: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 0px;;
    margin: 3px 0px;
}
.NewsText a{
    font-weight: 500;
    padding: 4px 7px;
    margin: 2px 4px;
    margin-left: 0px;
    background-color: var(--main-bg-1);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.NewsText a:hover{
    background-color: var(--main-text);
    color: var(--main-bg);
}
.Newscard .NewsImage{
    width: 100%;
}
.Newscard .NewsImage img{
    width: 100%;
    height: 140px;
    object-fit: cover;
    filter: saturate(0) brightness(0.7);
    -webkit-filter: saturate(0) brightness(0.7);

    animation: growimg .6s ease-out;
    -webkit-animation: growimg .6s ease-out;
    
    transition: all .6s ease-out;
    -webkit-transition: all .6s ease-out;
    -moz-transition: all .6s ease-out;
    -ms-transition: all .6s ease-out;
    -o-transition: all .6s ease-out;
}
.Newscard:hover .NewsImage img{
    filter: saturate(1) brightness(1);
    -webkit-filter: saturate(1) brightness(1);
}
.Newscard:last-of-type{
    margin-right: 0px;
}
@media screen and (max-width: 1440px) {
    .Newscard{
        width: calc(33.3% - 10px);
    }
}
@media screen and (max-width: 896px) {
    .Newscard{
        width: calc(50% - 10px);
        margin-right: 10px!important;
    }
}
@media screen and (max-width: 480px) {
    .Newscard{
        width: 100%;
        margin-right: 0px;
    }
}