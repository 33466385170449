.ContentWrapper{
    margin-left: 250px;
    padding: 80px;
    width: 1200px;
    min-height: 100vh;
    box-sizing: border-box;

    text-align: left;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.ContentWrapper.Fullpage{
    width: auto;
    padding: 0px;
    display: flex;
}

.TransitionPage{
    /*background-color: var(--red);
    */width: 100vw;
    height:100vh;
    padding: 3px;
    position:fixed;
    top: 0px;
    box-sizing: border-box;
    z-index: 10001;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: width 0.4s ease-out, padding .6s ease-out;
    -webkit-transition: width 0.4s ease-out, padding .6s ease-out;
    -moz-transition: width 0.4s ease-out, padding .6s ease-out;
    -ms-transition: width 0.4s ease-out, padding .6s ease-out;
    -o-transition: width 0.4s ease-out, padding .6s ease-out;
    animation: widthin .15s ease-out, colorChange 1.5s ease-out forwards;
    -webkit-animation: widthin .15s ease-out, colorChange 1.5s ease-out forwards;
}
.TransitionPage.exited{
    width: 0vw;
    padding: 0px;
}

.TransitionPage .InsideBox{
    width: 100%;
    height: 100%;
    /* box-sizing: content-box; */
    background-color: var(--main-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TransitionPage .MainLogo{
    width: 80px;
    fill: white;
    animation: opacity .4s ease-out, rotate360 1s ease-out .5s;
    -webkit-animation: opacity .4s ease-out, rotate360 1s ease-out .5s;
}
.TransitionPage .cls-1{fill:#ddd;}
.TransitionPage .cls-2{fill:#ddd;}
.TransitionPage .cls-3{fill:#ddd;}
.TransitionPage .cls-4{fill:#ddd;}

.TransitionPage p{
    animation: opacity .6s ease-out;
    -webkit-animation: opacity .6s ease-out;
}

@keyframes widthin{
    0%{width: 0vw;}
    100%{width: 100vw;}
}
@keyframes opacity{
    0%{opacity: 0;}
    100%{width: 1;}
}
@keyframes rotate360{
    0%{
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    40%{
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
    100%{
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }   
}
@keyframes scalein{
    0%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
    20%{
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
}
    100%{
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}   
}
@keyframes colorChange{
    0%{background-color: rgb(150, 150, 150)}
    30%{background-color: rgb(150, 150, 150)}
    40%{background-color: rgb(200, 200, 200);}
    60%{background-color: rgb(200, 200, 200);}
    80%{background-color: rgb(150, 150, 150);}
    90%{background-color: rgb(150, 150, 150);}
    100%{background-color: rgb(200, 200, 200);}
}


@media screen and (max-width: 1440px) {
    .ContentWrapper{
        margin-top: 80px;
        margin-right: auto;
        margin-left: auto;
        padding: 80px 10px;
        width: 900px;
        min-height: 100vh;
        box-sizing: border-box;
    
        text-align: left;
    
        display: flex;
        flex-direction: column;
        /* justify-content: center;
        align-items: center; */
    }
    .Layout{
        margin: auto;
    }
}

@media screen and (max-width: 896px) {
    .ContentWrapper{
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    
}