.Namecard{
    white-space: pre-line;
    width: 250px;
    min-height: 250px;
    background-color: var(--main-bg-3);
    border-left-style: ridge;
    border-left-width: 0px;

    padding: 10px 30px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
}

.Namecard .LineColor{
    width: 100%;
    height: 1.5px;
}
.Namecard > *{
    width: 100%;
}
.Namecard h3{
    margin-bottom: 0px;
    font-weight: 700;
}
.Namecard p{
    margin-bottom: 0px;
    font-size: 10px;
}
.Namecard h6{
    font-size: 16px;
    font-weight: 400;
}
.Namecard:hover{
    border-left-width: 2px;
}


@media screen and (max-width:1440px) {
    .Namecard{
        width: 280px;
    }
}
@media screen and (max-width:896px) {
    .Namecard{
        width: calc(50% - 5px);
    }
    .Namecard:nth-child(even){
        margin-right: 0px;
    }
}

@media screen and (max-width: 480px) {
    .Namecard{
        width: 100%;
        margin-right: 0px;
    }
}