.TitleContent{
    padding: 50px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;

    position: relative;
}
.TitleContent hr{
    margin: 0px;
    display: none;
}
.TitleContent > h1{
    color: "#dddddd" !important;
    /* line-height: 40px; */
}
.TitleContent > h1.Margin{
    margin-left: 100px !important;
}
.TitleContent h1 > span{
    font-weight: 400;
    font-size: 30px;
}
.TitleContent h1 > span::before{
    content: " :"
}
.TitleContent .Platforms{

}
.TitleContent .Platforms svg{
    width: 50px;
    height: 50px;
    margin: 0px 10px;
    fill: var(--main-text);
    align-self: center;
    transform: translateY(5px);
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
}
.TitleContent .Platforms svg:hover{
    fill: var(--main-text-60);
}
.TitleContent h1 > img{
    position: absolute;
    height: 80px;
    width: 80px;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}



@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 896px) {
    .TitleContent{
        padding: 30px;
        flex-direction: column;
    }
    .TitleContent .Platforms{
        display: flex;
        justify-content: center;
        margin-left: 100px;
    }
    .TitleContent .Platforms svg{
        width: 40px;
        height: 40px;
        margin: 10px 10px;
    }
    .WorkContent.TitleContent > h1{
        font-size: calc(2vw + 16px);
    }
    .TitleContent > h1.Margin{
        margin-left: 100px !important;
        text-align: center;
    }
    .TitleContent h1 > span{
        font-weight: 400;
        font-size: calc(2vw + 12px);
    }
}

@media screen and (max-width: 480px) {
    .TitleContent h1 > span{
        display: none;
    }
}