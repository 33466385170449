.VideoContent{
    /* width: 100%; */
    padding-top: 56.25%;
    position: relative;
}

.VideoContent iframe,
.VideoContent video{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
}

.VideoContent.full {
    width: 100%;
    padding-top: 56.25%;
}
.VideoContent.half {
    width: 50%;
    padding-top: 28.125%;
}
.VideoContent.third {
    width: 33.3%;
    padding-top: 18.75%;
}