.ImageSlider {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}
.ImageSlider > .SliderChild{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    /* transform: translateX(100%); */
    transition: transform .5s ease-out, opacity .5s ease-out;
    opacity: 0;
}
.ImageSlider > .SliderChild.current{
    opacity: 1;
    transform: translateX(0%);
    z-index: 1;
}
.ImageSlider > .SliderChild.prev{
    transform: translateX(100%);
}
.ImageSlider > .SliderChild.next{
    transform: translateX(-100%);
}
.ImageSlider > .SliderChild > img{
    max-height: 100%;
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
}
.ImageSlider > .SlideButton{
    position: absolute;
    top: 0px;
    width: 15%;
    height: 100%;
    text-align: center;
}
.ImageSlider > .Left{
    z-index: 2;
    background-image: linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
    left: 0px;
}
.ImageSlider > .Left:hover{
    background-image: linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0));
}
.ImageSlider > .Right{
    z-index: 2;
    background-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.1));
    right: 0px;
}
.ImageSlider > .Right:hover{
    background-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,0.3));
    right: 0px;
}
.ImageSlider > .SlideButton svg{
    width: 40px;
    height: 40px;
    fill: white;
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(50%, -50%);
}
.ImageSlider > .SlideButton:hover svg {
    fill: white;
}