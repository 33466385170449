.WorkCard{
    width: 100%;
    height: 250px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    position: relative;
    /* background-color: rgba(0,0,0,0.5); */

    cursor: pointer;
}
.WorkCard *{
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}
.WorkCard.Fullpage{
    margin-bottom: 0px;
}
.WorkCard:hover{
    color: var(--main-bg);
}
.WorkCard h1{
    font-size: 80px;
    margin: 0px;
}
.WorkCard p{
    margin: 0px;
    font-weight: 500;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
}

.WorkCard .WorkImage{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.WorkCard .WorkImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* filter: brightness(0.3) saturate(0) contrast(0.7);
    -webkit-filter: brightness(0.3) saturate(0) contrast(0.7); */
}
.WorkCard:hover .WorkImage img{
    /* filter: brightness(1) saturate(0.7) contrast(0.7);
    -webkit-filter: brightness(1) saturate(0.7) contrast(0.7); */
}

.WorkCard .WorkContent{
    height: 100%;
    width: 100%;

    background-color: rgba(0,0,0,0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.WorkCard:hover .WorkContent{
    background-color: rgba(255,255,255,0.5);
}

.WorkCard .WorkColor{
    width: 100%;
    height: 100%;
}
.WorkCard .Title{
    transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
}
.WorkCard .StatusTag{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 120px;

    opacity: 0.5;
}
.WorkCard:hover .StatusTag{
    opacity: 1;
}
.WorkCard .StatusTag > img{
    width: 100%;
}

.WorkCard .Description{
    position: absolute;
    top: 20px;
}

.WorkCard .Platforms{
    width: 100%;

    z-index: 10;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.WorkCard .Platforms svg{
    fill: var(--main-text);
    width: 40px;
    height: 40px;
    margin: 0px 10px;
}
.WorkCard:hover .Platforms svg{
    fill: var(--main-bg);
}
.WorkCard .Platforms svg:hover{
    fill: var(--main-bg-5);
}



@media screen and (max-width: 1440px) {
    .WorkPage .WorkCard h1.Title,
    .WorkCard h1.Title {
        font-size: calc(2.7vw + 40px);
        margin: 0px;
    }
    .WorkCard .StatusTag{
        width: 80px;
    }
}

@media screen and (max-width: 896px) {
    
}

@media screen and (max-width: 480px) {
    
}